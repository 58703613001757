import {AppConfig, ServerConfig} from '../src/app/core/config/config.types'
import {InjectionToken} from '@angular/core'

export const readAppConfig = () => readJsonEnv<AppConfig>('CONFIG')
export const readServerConfig = () => readJsonEnv<ServerConfig>('SERVER_CONFIG')

function readJsonEnv<T>(env: string): T {
  const value = process.env[env]
  if (!value) {
    throw new Error(`Could not find ${env} environment variable`)
  }
  return JSON.parse(value) as T
}

export const APP_CONFIG_TOKEN = new InjectionToken<AppConfig>('BgoAppConfig')
