import {Injectable, Optional} from '@angular/core'
import {Router} from '@angular/router'
import {Breadcrumb, BreadcrumbHint, ErrorMonitor, EventProcessor} from './error-monitoring.types'

@Injectable({
  providedIn: 'root',
})
export class SentryErrorMonitoringService implements ErrorMonitor {
  // sentry is lazily loaded to improve startup performance (bundle size)
  // this is a proxy object where we avoid importing type defs and declare as minimum as possible
  // see @sentry/angular-ivy for full type defs
  private _sentry:
    | {
        captureException: (exception: unknown) => string
        addBreadcrumb: (breadcrumb: Breadcrumb, hint?: BreadcrumbHint) => void
        addEventProcessor: (processor: EventProcessor) => void
        setUser: (user: unknown) => void
        setContext: (name: string, context: unknown) => void
        traceService: unknown
      }
    | undefined

  private eventProcessors: EventProcessor[] = []
  private user: Record<string, unknown> | null = null
  private readonly context = new Map<string, Record<string, unknown> | null>()

  constructor(@Optional() private readonly router?: Router) {}

  private async loadSentry() {
    if (!this._sentry) {
      console.log('initializing sentry')
      // only import what we need, allows tree shaking
      const {
        browserApiErrorsIntegration,
        captureException,
        addBreadcrumb,
        init,
        TraceService,
        browserTracingIntegration,
        addEventProcessor,
        setUser,
        setContext,
      } = await import('@sentry/angular-ivy')
      init({
        dsn: 'https://9935b9777a1c46ab963f39e82a6b7f44@o58092.ingest.sentry.io/5353411',
        integrations: [
          // browserApiErrorsIntegration has to be configured to disable XMLHttpRequest wrapping, as we are going to handle
          // http module exceptions manually in Angular's ErrorHandler and we don't want it to capture the same error twice.
          browserApiErrorsIntegration({XMLHttpRequest: false}),
          // tracing is enabled, but as we only load sentry once an error occurs, tracing won't start until then
          // we could load sentry earlier if we wanted better tracing analytics
          browserTracingIntegration(),
        ],
        // capture a fraction of traces for perf monitoring, as suggested by sentry
        tracesSampleRate: 0.1,
      })

      this._sentry = {
        captureException,
        addBreadcrumb,
        addEventProcessor,
        setUser,
        setContext,
        traceService: this.router ? new TraceService(this.router) : undefined, // init trace service to report traces
      }

      // lazily add event processors, user and context
      for (const processor of this.eventProcessors) {
        addEventProcessor(processor)
      }
      if (this.user) {
        setUser(this.user)
      }
      if (this.context) {
        for (const ctx of this.context) {
          console.log('adding context', ctx[0], ctx[1])
          setContext(ctx[0], ctx[1])
        }
      }
    }
    return this._sentry
  }

  async captureException(exception: unknown): Promise<string> {
    const sentry = await this.loadSentry()
    const exceptionId = sentry.captureException(exception)
    console.debug(`Error reported https://buildigo.sentry.io/issues/5445177051/events/${exceptionId} `)
    return exceptionId
  }

  async addBreadcrumb(breadcrumb: Breadcrumb, hint?: BreadcrumbHint): Promise<void> {
    const sentry = await this.loadSentry()
    sentry.addBreadcrumb(breadcrumb, hint)
  }

  addEventProcessor(processor: EventProcessor): void {
    this.eventProcessors.push(processor)
    if (this._sentry) {
      this._sentry.addEventProcessor(processor)
    } else {
      // if sentry is not loaded yet, the processor will be added in loadSentry
    }
  }

  setContext(name: string, value: Record<string, unknown> | null): void {
    this.context.set(name, value)
    if (this._sentry) {
      for (const ctx of this.context) {
        this._sentry.setContext(ctx[0], ctx[1])
      }
    } else {
      // if sentry is not loaded yet, the processor will be added in loadSentry
    }
  }

  setUser(user: Record<string, unknown> | null): void {
    this.user = user
    if (this._sentry) {
      this._sentry.setUser(user)
    } else {
      // if sentry is not loaded yet, the processor will be added in loadSentry
    }
  }
}
