import {AppFeatureFlags} from '../feature-flag/feature-flag.types'

export interface AppConfig {
  stage: string
  version: string
  apiUrl: string
  pubSubApiUrl: string
  seoDomain: string
  protocol: string
  cognito: {
    region: string
    poolId: string
    appId: string
    oauth?: {
      domain: string
    }
  }
  datocms: {
    // preview means unpublished content is visible
    // https://www.datocms.com/docs/content-delivery-api/api-endpoints#preview-mode-to-retrieve-draft-content
    preview: boolean
    apiToken: string
    // when specified, the environment is used to fetch content from DatoCMS, otherwise uses the primary environment
    environment?: string
  }
  googleMaps: {
    apiKey: string
  }
  features: AppFeatureFlags
  captcha: {
    siteKey: string
  }
}

export interface ServerConfig {
  // Non default additions to the content security policy. All of the values will be appended to the default csp.
  csp?: {
    connectSrc?: string[]
    scriptSrc?: string[]
    imageSrc?: string[]
    frameSrc?: string[]
  }
  robotsTagHeader: string
  // Redirect url for the "bring a friend" feature, without any query params
  bringAFriendRedirectUrl: string
}

/**
 * private fields for lambdas, not to be shared clientside.
 *
 * should not be used in the SSR lambda.
 */
export interface LambdaConfig extends AppConfig {
  region: string
  buckets: {
    assets: string
  }
  datocmsPrivate: {
    cmaApiToken: string
  }
}

export const defaultConfig: AppConfig = {
  stage: 'error',
  version: 'error',
  apiUrl: 'default',
  pubSubApiUrl: 'default',
  cognito: {
    region: 'default',
    poolId: 'default',
    appId: 'default',
  },
  seoDomain: 'default',
  protocol: 'default',
  datocms: {
    preview: false,
    apiToken: 'default',
  },
  googleMaps: {
    apiKey: 'default',
  },
  features: {
    enableExperimental: false,
  },
  captcha: {
    siteKey: 'default',
  },
}
