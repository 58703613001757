import type {AppConfig} from './config.types'
import {defaultConfig} from './config.types'
import {Inject, Injectable, Optional, PLATFORM_ID} from '@angular/core'
import {APP_CONFIG_TOKEN} from '../../../../server/config'
import {isPlatformServer} from '@angular/common'
import {SentryErrorMonitoringService} from '../error/error-monitoring.service'

@Injectable({providedIn: 'root'})
export class ConfigService {
  readonly config: AppConfig

  constructor(
    @Optional() @Inject(APP_CONFIG_TOKEN) injectedConfig: AppConfig,
    @Inject(PLATFORM_ID) platformId: Record<string, unknown>,
  ) {
    if (injectedConfig) {
      // in most cases, the config is (and should be) injected through APP_CONFIG_TOKEN
      this.config = injectedConfig
    } else if (isPlatformServer(platformId)) {
      // when doing local development with SSR, vite will run the app itself without our server code, so we
      // don't have the opportunity to inject the config through an angular token
      // we load it from the process environment variable instead
      this.config = JSON.parse(process.env['CONFIG']!) as AppConfig
    } else {
      // should never happen, or else something is not wired correctly
      throw new Error('Could not find config in injection tokens or env variables, this is a development error')
    }
  }

  static async loadConfig(): Promise<AppConfig> {
    try {
      console.debug('Configuration loading from remote')
      return await this.fetchConfig('/config.json')
    } catch (err) {
      // report the error
      const errorMonitor = new SentryErrorMonitoringService()
      await errorMonitor.addBreadcrumb({level: 'error', message: err.msg || err})
      await errorMonitor.captureException(new Error(`Could not properly configure application, err: ${err.msg || err}`))
      // could not find the config, this is a fatal error
      return defaultConfig
    }
  }

  static async fetchConfig<T>(url: string): Promise<T> {
    const res = await fetch(url)
    if (res.status >= 200 && res.status < 300) {
      const body = await res.json() // "return await" is important or else error won't be caught
      return body as T
    } else {
      const body = await res.text()
      throw new Error(`Could not fetch config.json, status: ${res.status}, body: ${body}`)
    }
  }

  isProduction(): boolean {
    return this.config.stage === 'prod'
  }
}
